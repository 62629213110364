// components/FloatButton.js
import React, { FC, useEffect, useState } from 'react'
import { Button, Box, VStack, Text, Image } from '@chakra-ui/react'
import IAButton from '../../Assets/ia-button.png'
import { Chat } from '../Chatbot/Chat'
import { useChat } from '../../Hooks/useChat'
import { CreateThreadUseCase } from '../../Usecase/Chat/create-thread-usecase'
import { SendMessageUseCase } from '../../Usecase/Chat/send-message-usecase'
import { RatingMessageUseCase } from '../../Usecase/Chat/rating-message-usecase'
import { ListSuggestionUseCase } from '../../Usecase/Chat/list-suggestion-usecase'
import { ListFavoritesUseCase } from '../../Usecase/Chat/list-favorites-usecase'
import { CreateFavoritesUseCase } from '../../Usecase/Chat/create-favorite-usecase'
import { DeleteFavoritesUseCase } from '../../Usecase/Chat/delete-favorite-usecase'
import { Welcome } from '../Welcome'
import { Loader } from '../Loader'
import { useScreenSizes } from '../../utils/screenSizes'
import { useNavigate } from 'react-router-dom'

const attemptToCreateThreadIdLimit = 4

interface Props {
  createThreadUseCase: CreateThreadUseCase
  sendMessageUseCase: SendMessageUseCase
  ratingMessageUseCase: RatingMessageUseCase
  listSuggestionUseCase: ListSuggestionUseCase
  listFavoritesUseCase: ListFavoritesUseCase
  createFavoritesUseCase: CreateFavoritesUseCase
  deleteFavoritesUseCase: DeleteFavoritesUseCase
}

const IAFloat: FC<Props> = ({ createThreadUseCase, ratingMessageUseCase, sendMessageUseCase, listSuggestionUseCase, listFavoritesUseCase, createFavoritesUseCase, deleteFavoritesUseCase }) => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [errorLimitAttemptThreadId, setErrorLimitAttemptThreadId] = useState(false)
  const { isMobile } = useScreenSizes()
  const { handleCreateThread, threadId, isLoading, messages, handleClearChat, handleSendMessage, chatLoading, handleRatingMessage, attemptToCreateThreadId, suggestions, handleListSuggestions, favorites, handleListFavorites, handleCreateFavorite, handleDeleteFavorite, threadIsLoading, start, setStart } = useChat()
  const navigate = useNavigate()
  const handleStart = async (): Promise<void> => {
    setStart(true)
  }

  useEffect(() => {
    if (attemptToCreateThreadId === 0) return

    if (attemptToCreateThreadId === attemptToCreateThreadIdLimit) {
      setErrorLimitAttemptThreadId(true)
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async (): Promise<void> => {
      await handleCreateThread()
    }, 3000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptToCreateThreadId])

  const toggleChat = () => {
    if (isMobile) {
      navigate('/ai?fromFloatButton=true')
      return
    }
    setIsChatOpen(!isChatOpen)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleListSuggestions()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleListFavorites()
  }, [])

  return (
    <>
     {threadIsLoading && <Loader /> }
      <Image
        src={IAButton}
        w='100px'
        h='100px'
        position="fixed"
        bottom={isMobile ? '11px' : '16px'}
        right={isMobile ? '6px' : '40px' }
        cursor='pointer'
        onClick={toggleChat}
        zIndex={999}
      />

      {isChatOpen && (
        <Box
          position="fixed"
          bottom="70px"
          right="24px"
          width="438px"
          height="70%"
          backgroundColor="white"
          borderRadius="18px"
          boxShadow="-4px 7px 31px 0px #00000024"

          zIndex="99"
        >
          {start && threadId ? <Chat handleClearChat={handleClearChat} handleDeleteFavorite={handleDeleteFavorite} handleCreateFavorite={handleCreateFavorite} mode='popup' messages={messages} isLoading={isLoading} handleSendMessage={handleSendMessage} chatLoading={chatLoading} handleRatingMessage={handleRatingMessage} closeFunction={toggleChat} suggestions={suggestions} favorites={favorites} start={start}/> : <Welcome mode='popup' onClick={handleStart} errorLimitAttemptThreadId={errorLimitAttemptThreadId} />}

        </Box>
      )}
    </>
  )
}

export { IAFloat }
