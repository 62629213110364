import { object, string } from 'yup'
import {
  AuthenticationApiRemoteService,
  TCreateMetabaseTokenResponse
} from '../../Services/protocols/service-authentication'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'

export class CreateMetabaseTokenUseCase
implements IFormUsecase<void, TCreateMetabaseTokenResponse> {
  constructor (private readonly repository: AuthenticationApiRemoteService) {}

  public async handle (): Promise<Result<TCreateMetabaseTokenResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.createMetabaseToken()

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao realizar CreateMetabaseToken'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    username: string().required('E-mail é obrigatório'),
    password: string().required('Senha é obrigatória')
  })
}
