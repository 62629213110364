import {
  FavoritesApiRemoteService
} from '../../Services/protocols/service-favorites'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class CreateFavoritesUseCase implements IUsecase<string, string | undefined> {
  constructor (private readonly repository: FavoritesApiRemoteService) {}

  public async handle (name: string): Promise<Result<string | undefined>> {
    try {
      const response = await this.repository.createFavorite(name)

      const data = response.getValue()

      return Result.ok(data ?? undefined)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
