import {
  SuggestionApiRemoteService, TListSuggestionResponse
} from '../../Services/protocols/service-suggestion'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ListSuggestionUseCase
implements IUsecase<void, TListSuggestionResponse[]> {
  constructor (private readonly repository: SuggestionApiRemoteService) {}

  public async handle (): Promise<Result<TListSuggestionResponse[]>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.listSuggestions()

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao buscar as sugestões'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
