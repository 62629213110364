import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'

export interface TListSuggestionResponse {
  'category': string
  'market': string
  'suggestions': Array<{
    'id': string
    'category': string
    'market': string
    'description': string
  }>
}[]

export class SuggestionApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public listSuggestions = async (): Promise<Result<TListSuggestionResponse[]>> => {
    try {
      const result = await this.service.get('/suggestions')

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
