// router.tsx
import React from 'react'
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import { CUSTOMER } from '../Config/constants'
import { SignIn } from '../Pages/SignIn'
import { ChatbotPage } from '../Pages/Chatbot'
import { NotFoundPage } from '../Pages/NotFound'
import { FullEmbbedMetaBasePage } from '../Pages/FullEmbbedMetaBase'
import api from '../Services/api'
import { LoginUseCase } from '../Usecase/Auth/login-usecase'
import { AuthenticationApiRemoteService } from '../Services/protocols/service-authentication'
import { CreateThreadUseCase } from '../Usecase/Chat/create-thread-usecase'
import { ChatApiRemoteService } from '../Services/protocols/service-chat'
import { SendMessageUseCase } from '../Usecase/Chat/send-message-usecase'
import { RatingMessageUseCase } from '../Usecase/Chat/rating-message-usecase'
import { SuggestionApiRemoteService } from '../Services/protocols/service-suggestion'
import { ListSuggestionUseCase } from '../Usecase/Chat/list-suggestion-usecase'
import { ListFavoritesUseCase } from '../Usecase/Chat/list-favorites-usecase'
import { FavoritesApiRemoteService } from '../Services/protocols/service-favorites'
import { CreateFavoritesUseCase } from '../Usecase/Chat/create-favorite-usecase'
import { DeleteFavoritesUseCase } from '../Usecase/Chat/delete-favorite-usecase'
import { CreateMetabaseTokenUseCase } from '../Usecase/Auth/create-metabase-token-usecase'

const isAuthenticated = (): any => {
  return localStorage.getItem(`${CUSTOMER}:token`) !== null
}

// Rota privada que requer autenticação
const PrivateRoute = ({ element }: any): JSX.Element => {
  return isAuthenticated()
    ? (
    <Flex
      flexDirection="row"
      width="100%"
      height="100vh"
      sx={{ overflowX: 'hidden !important' }}
    >
      <Flex width="100%" height="100vh">
        {element}
      </Flex>
    </Flex>
      )
    : (
    <Navigate to="/signin" replace />
      )
}

const router = createBrowserRouter([
  {
    path: '/signin',
    element: (
      <SignIn
        loginUseCase={new LoginUseCase(new AuthenticationApiRemoteService(api))}
      />
    )
  },
  {
    path: '/',
    element: <PrivateRoute element={<FullEmbbedMetaBasePage createMetabaseTokenUseCase={new CreateMetabaseTokenUseCase(new AuthenticationApiRemoteService(api))} deleteFavoritesUseCase={new DeleteFavoritesUseCase(new FavoritesApiRemoteService(api))} createFavoritesUseCase={new CreateFavoritesUseCase(new FavoritesApiRemoteService(api))} createThreadUseCase={new CreateThreadUseCase(new ChatApiRemoteService(api))} sendMessageUseCase={new SendMessageUseCase(new ChatApiRemoteService(api))} ratingMessageUseCase={new RatingMessageUseCase(new ChatApiRemoteService(api))} listSuggestionUseCase={new ListSuggestionUseCase(new SuggestionApiRemoteService(api))} listFavoritesUseCase={new ListFavoritesUseCase(new FavoritesApiRemoteService(api))}/> } />
  },
  {
    path: '/ai',
    element: <PrivateRoute element={<ChatbotPage deleteFavoritesUseCase={new DeleteFavoritesUseCase(new FavoritesApiRemoteService(api))} createFavoritesUseCase={new CreateFavoritesUseCase(new FavoritesApiRemoteService(api))} listFavoritesUseCase={new ListFavoritesUseCase(new FavoritesApiRemoteService(api))} createThreadUseCase={new CreateThreadUseCase(new ChatApiRemoteService(api))} sendMessageUseCase={new SendMessageUseCase(new ChatApiRemoteService(api))} ratingMessageUseCase={new RatingMessageUseCase(new ChatApiRemoteService(api))} listSuggestionUseCase={new ListSuggestionUseCase(new SuggestionApiRemoteService(api)) } />} />
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
])

const Router = (): JSX.Element => {
  return <RouterProvider router={router} />
}

export default Router
