import React, { FC } from 'react'
import { Image } from '@chakra-ui/react'
import BrandArrow from '../../Assets/brand-arrow.png'
import Brand from '../../Assets/brand-image.png'
import { useScreenSizes } from '../../utils/screenSizes'

interface Props {
  brandStyle?: object
  arrowStyle?: object
}

const Logo: FC<Props> = ({ brandStyle = {}, arrowStyle = {} }) => {
  const { isMobile } = useScreenSizes()

  const handleNavigation = (): void => {
    window.location.href = 'https://app.nalk.com.br'
  }

  return (

      <Image onClick={handleNavigation} cursor='pointer' src={Brand} alt="Brand" w="115px" h="32px" marginLeft={isMobile ? 0 : '36px'} {...brandStyle} />

  )
}

export { Logo }
