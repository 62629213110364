import { Box, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, useTheme } from '@chakra-ui/react'
import React, { FC } from 'react'
import { MdLogin } from 'react-icons/md'
import { StorageLocalstorage } from '../../../Shared/storage/storage-localstorage'
import { useNavigate } from 'react-router-dom'
import { clearCookie } from '../../../utils/cookies'

interface IModalSignOutProps {
  onClose: () => void

}

const ModalSignOut: FC<IModalSignOutProps> = ({ onClose }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const signOut = (): void => {
    StorageLocalstorage.clearAll()
    clearCookie({ name: 'metabase.EMBEDDED_SESSION' })
    clearCookie({ name: 'metabase.TIMEOUT' })
    navigate('/signin')
  }

  return (
        <Modal isOpen onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent width='295px' height='216px' shadow='box-shadow: 0px 0px 12px 0px #00000026' bgColor={theme.colors.white.primary} border='none' borderRadius='16px'>

          <ModalBody padding="48px">
          <ModalCloseButton w='15px' h='15px' marginTop='5px' marginRight='3px' />
          <Flex direction="column" alignItems="center" >
              <MdLogin size='24px' />
              <Text fontWeight={700} fontSize='18px' lineHeight='19px' color={theme.colors.blue.default} textAlign='center' marginTop='8px' >Certeza que deseja sair da sua conta?</Text>

              <Flex alignItems="center" marginTop="29px">
                  <Box bgColor={theme.colors.blue.default} padding="4px 16px" borderRadius="40px" cursor="pointer" onClick={onClose}>
                      <Text fontWeight={700} fontSize="14px" color={theme.colors.white.primary}>Cancelar</Text>
                  </Box>
                  <Box bgColor={theme.colors.white.primary} padding="4px 16px" borderRadius="40px" cursor="pointer" onClick={signOut}>
                      <Text fontWeight={500} fontSize="14px" color={theme.colors.blue.default}>Sim, sair</Text>
                  </Box>
              </Flex>
          </Flex>

          </ModalBody>

          </ModalContent>
      </Modal>
  )
}

export { ModalSignOut }
