
import React, { FC } from 'react'

const ArrowLeftIcon: FC = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="41.5" width="41" height="41" rx="20.5" transform="rotate(-90 0.5 41.5)" stroke="#4C5773"/>
<path d="M26.8334 21.0001H15.1667M15.1667 21.0001L21.0001 26.8334M15.1667 21.0001L21.0001 15.1667" stroke="#4C5773" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export { ArrowLeftIcon }
