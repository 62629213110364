import React, { FC } from 'react'
import Lottie from 'lottie-react'
import Animation from '../../../Assets/Animations/loader-message.json'
import { Box } from '@chakra-ui/react'

const LoaderMessage: FC = () => {
  return (
      <Lottie animationData={Animation} loop={true} style={{ width: 50, height: 30, marginTop: 10 }} />
  )
}

export { LoaderMessage }
