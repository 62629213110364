/* eslint-disable @typescript-eslint/no-misused-promises */
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, useTheme, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { IModeChatType } from '../Interface/IModeChat'
import { TListSuggestionResponse } from '../../../Services/protocols/service-suggestion'
import { useScreenSizes } from '../../../utils/screenSizes'
import { HandleSendMessageProps } from '../../../Hooks/useChat'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'

interface Props {
  mode: IModeChatType
  data: TListSuggestionResponse[]
  handleSendMessage: (data: HandleSendMessageProps) => Promise<void>
}

const Suggestions: FC<Props> = ({ mode, data, handleSendMessage }) => {
  const theme = useTheme()
  const { isMobile } = useScreenSizes()

  const formatText = (text: string): React.ReactNode => {
    if (!text) return ''

    const capitalizedText = capitalizeFirstLetter(text)

    // Divide o texto com base no primeiro ":" encontrado
    const [beforeColon, afterColon] = capitalizedText.split(/:(.+)/)

    return (
      <>
        <strong>{beforeColon.trim()}</strong>
        {afterColon && `: ${afterColon}`}
      </>
    )
  }

  return (
    <Flex
      direction="column"
      flex="1"
      overflow="hidden"
      width="100%"
      maxWidth="650px"
      height={mode === 'popup' ? '520px' : '100%'}
      overflowY="auto"
      marginTop={isMobile ? '160px' : 0}
    >
      <Accordion allowToggle height={mode === 'popup' ? '48vh' : isMobile ? '70vh' : '100%'}>
        {data?.map((item) => (
          <AccordionItem border="none" key={item.category} >
            <h2>
              <AccordionButton minHeight="44px" paddingX={mode === 'popup' ? '24px' : '14px'}>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={theme.colors.blue.default}
                  fontWeight={700}
                  fontSize="14px"
                >
                  {item?.category?.toUpperCase()}
                </Box>
                <AccordionIcon w="24px" h="24px" />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {item?.suggestions?.map((suggestion) => (
                <Box
                  as="button"
                  key={suggestion.id}
                  padding="10px"
                  borderRadius={10}
                  width="100%"
                  textAlign="left"
                  onClick={async () => await handleSendMessage({ text: suggestion.description })}
                  _hover={{ bg: theme.colors.gray[80], fontWeight: 600 }} // Adiciona o efeito de hover
                >
                  <Text>{formatText(suggestion?.description)}</Text>
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  )
}

export { Suggestions }
