/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box, Flex, Text, useTheme } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { UserIcon } from '../../../Assets/IconsComponents/UserIcon'
import { useScreenSizes } from '../../../utils/screenSizes'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { IModeChatType } from '../Interface/IModeChat'
import { IMessagesChatProps } from '../../../Hooks/useChat'
interface Props {
  chat: IMessagesChatProps
  mode: IModeChatType
  handleCreateFavorite: (name: string, messageId: string) => Promise<string | undefined>

  handleDeleteFavorite: (id: string) => Promise<void>

}
const UserMessage: FC<Props> = ({ chat, mode, handleCreateFavorite, handleDeleteFavorite }) => {
  const [isLiked, setIsLiked] = useState('')
  const { isMobile } = useScreenSizes()

  const theme = useTheme()

  const handleFavorite = useCallback(async (like: string, name: string) => {
    try {
      if (like) {
        const id = await handleCreateFavorite(name, String(chat?.chatId))

        if (id) {
          setIsLiked(id)
        }
      } else {
        // ToDo
        // para remover um favorito que acabou de ser adicionado, precisamos ter o id dele que nessse momento não temos.
        await handleDeleteFavorite(chat?.favoriteId ?? isLiked)
        setIsLiked('')
      }

      // aFavoriteMessage({ chatId: String(chatId), rating: rate })
    } catch (error) {
      console.log('handleFavorite error: ', error)
    }
  }, [handleCreateFavorite, handleDeleteFavorite, chat, isLiked])

  useEffect(() => {
    if (chat?.favoriteId) {
      setIsLiked(chat.favoriteId)
    }
  }, [chat])

  return (

    <Flex w='100%' px={ mode === 'popup' ? '24px' : isMobile ? '20px' : '30px'} marginBottom={mode === 'popup' ? '12px' : isMobile ? '26px' : '6%'} >

      <Flex width='49px' height='49px' borderRadius='50%' alignItems='center' justifyContent='center' backgroundColor='gray.80' flexShrink={0}>
        <UserIcon />
      </Flex>

      <Box marginLeft="22px" width='100%'>
        <Text align="left" fontSize="16px" fontWeight={800} color="gray.900" fontFamily='Gantari-Bold'>Você</Text>

        <Flex flexDir="column" alignItems="flex-start" flex={1}>

        <Flex flex={1}>
          <Text as="span" fontSize="1rem" color='#667085' fontFamily='Gantari' marginTop='5px' wordBreak="break-word">{chat?.message}</Text>
        </Flex>

        {chat?.chatId
          ? <Flex gap="10px" alignItems="center" mt={isMobile ? '8px' : '12px'} width='100%'>
          <Box marginLeft="auto">
          {isLiked
            ? (
            <FaHeart
            color={theme.colors.gray.third}
              onClick={async () => await handleFavorite('', chat?.message)}
              cursor="pointer"
            />
              )
            : (
            <FaRegHeart
            color={theme.colors.gray.third}
              onClick={async () => await handleFavorite('fake-new-favorite-id', chat?.message)}
              cursor="pointer"
            />
              )}
              </Box>

          </Flex>
          : <Box mt={isMobile ? '8px' : '12px'} height='16px' />}

          </Flex>
      </Box>

    </Flex>
  )
}

export { UserMessage }
