import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  fonts: {
    body: 'Gantari',
    bodyBold: 'Gantari-Bold'
  },
  colors: {
    blue: {
      default: '#667085',
      500: '#2D3748'
    },
    gray: {
      primary: '#F2F4F7',
      1000: '#101828',
      900: '#667085',
      800: '#F9FBFC',
      700: '#344054',
      600: '#4C5773',
      500: '#616480',
      400: '#797d9a',
      300: '#D0D5DD',
      200: '#b3b5c6',
      100: '#E9E9E9',
      50: '#eeeef2',
      80: '#EFF4FA',
      third: '#B1C0E0'
    },
    red: {
      primary: '#fe4345',
      50: '#FED7D7',
      100: '#F28B82',
      500: '#E53E3E'
    },
    green: {
      primary: '#C1D632',
      300: '#C6F6D5',
      500: '#38A169'
    },
    black: {
      primary: '#080909'
    },
    wine: {
      primary: '#771731',
      200: '#993b58',
      400: '#590829',
      500: '#480027',
      600: '#380024'
    },
    white: {
      primary: '#FFF',
      secondary: '#F5F6EB'
    }
  },
  styles: {
    global: {
      body: {
        bg: '#fff',
        color: 'gray.500'
      },
      button: {
        outline: 0,
        border: 0,
        outlineOffset: 0
      },
      a: {
        outline: 0,
        border: 0
      },
      '#chakra-toast-manager-top': {
        color: '#fff'
      },
      '.chakra-toast': {
        color: '#fff'
      }
    }
  }
})
