import { useBreakpointValue } from '@chakra-ui/react'

const useScreenSizes = (): { isMobile: boolean | undefined } => {
  const isMobile = useBreakpointValue({
    base: true, // Considera qualquer dispositivo como mobile
    sm: true, // Small devices and up
    md: false, // Medium devices and up
    lg: false, // Large devices and up
    xl: false // Extra large devices and up
  })

  return {
    isMobile
  }
}

export { useScreenSizes }
