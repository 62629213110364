import React, { FC } from 'react'

const ChevronLeft: FC = ({ ...rest }) => {
  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8333 9.99996H4.16663M4.16663 9.99996L9.99996 15.8333M4.16663 9.99996L9.99996 4.16663" stroke="#C1D632" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export { ChevronLeft }
