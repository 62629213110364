import { Box, Flex, Text, useTheme } from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { theme } from '../../../Styles/theme'
import { IModeChatType } from '../Interface/IModeChat'
import { useScreenSizes } from '../../../utils/screenSizes'

interface ITabsProps {
  setActiveTab: (index: number) => void
  activeTab: number
  mode: IModeChatType
  disabled?: boolean
}

const Tabs: FC<ITabsProps> = ({ setActiveTab, activeTab, mode, disabled }) => {
  const theme = useTheme()
  const { isMobile } = useScreenSizes()

  return (
        <Flex position={isMobile ? 'fixed' : 'relative'} alignItems="center" marginTop={isMobile ? '60px' : mode === 'fullscreen' ? '3%' : 0} marginBottom={mode === 'fullscreen' ? '4%' : 0} justifyContent='center' bgColor="white" zIndex={999} height={isMobile ? '100px' : 'auto'} width={isMobile ? '100%' : 'auto'}>
            <Box bgColor={activeTab === 0 ? theme.colors.blue.default : theme.colors.white.primary} padding="4px 16px" borderRadius="40px" cursor="pointer" onClick={() => setActiveTab(0)}>
                <Text fontWeight={activeTab === 0 ? 600 : 500} fontSize="14px" color={activeTab === 0 ? theme.colors.white.primary : theme.colors.blue.default}>AI Nalk</Text>
            </Box>
            {/* <Box bgColor={activeTab === 1 ? theme.colors.blue.default : theme.colors.white.primary} padding="4px 16px" borderRadius="40px" cursor="pointer" onClick={() => setActiveTab(1)}>
                <Text fontWeight={activeTab === 1 ? 600 : 500} fontSize="14px" color={activeTab === 1 ? theme.colors.white.primary : theme.colors.blue.default}>Sugestões</Text>
            </Box> */}
            <Box bgColor={activeTab === 2 ? theme.colors.blue.default : theme.colors.white.primary} padding="4px 16px" borderRadius="40px" cursor="pointer" onClick={() => setActiveTab(2)}>
                <Text fontWeight={activeTab === 2 ? 600 : 500} fontSize="14px" color={activeTab === 2 ? theme.colors.white.primary : theme.colors.blue.default}>Favoritas</Text>
            </Box>
        </Flex>
  )
}

export { Tabs }
