/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, useTheme, Image } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useScreenSizes } from '../../utils/screenSizes'
import { Logo } from '../Logo'
import Brand from '../../Assets/brand-image.png'
import { IModeChatType } from '../Chatbot/Interface/IModeChat'
import { ToastCustom } from '../ToastCustom'

interface Props {
  onClick?: () => Promise<void>
  errorLimitAttemptThreadId: boolean
  mode: IModeChatType
}

const Welcome: FC<Props> = ({ onClick, errorLimitAttemptThreadId, mode }) => {
  const { isMobile } = useScreenSizes()
  const theme = useTheme()
  return (
    <Flex flex={1} direction='column' paddingTop={mode === 'popup' ? '0px' : isMobile ? '40px' : '70px'} height={mode === 'popup' ? '700px' : '800px'}
    overflowY="auto" >
        {!isMobile && mode !== 'popup' && <Logo /> }

        {errorLimitAttemptThreadId && <ToastCustom type='fail' fromToast={false} title='AI Indisponível no momento :(' description='Por favor tente novamente mais tarde ou entre em contato com o time de suporte.' />}

           <Flex width="100%" flexDirection='column' >

            <Box padding='60px 52px'>
              <Text fontWeight='900' fontSize='32px' color={theme.colors.gray[900]} fontFamily={theme.fonts.bodyBold}>Bem-vindo</Text>

              <Text fontWeight='400' fontSize='14px' color={theme.colors.gray[900]} marginTop='13%' fontFamily={theme.fonts.body}>Compreenda a estrutura de suas tabelas no seu</Text>
              <a href='https://app.nalk.com.br/browse/databases' target='_blank' rel="noreferrer"><Text fontWeight='700' fontSize='18px' textDecoration='underline' color={theme.colors.gray[900]}>Banco de dados</Text></a>

              <Text fontWeight='400' fontSize='14px' color={theme.colors.gray[900]} marginTop='8%'>Especifique em suas perguntas variáveis como:</Text>
              <Text fontWeight='700' fontSize='18px' textDecoration='underline' color={theme.colors.gray[900]}>Indicador, etapa, período e pipeline</Text>

              <Text fontWeight='400' fontSize='14px' color={theme.colors.gray.third} marginTop='14%'>Em conformidade com a LGPD, não exibimos dados sensíveis como nomes e IDs.</Text>
              <Text fontWeight='400' fontSize='14px' color={theme.colors.gray.third} marginTop='6%'>Usamos como fonte de dados tabelas Cohort (vendas) e de mídia paga (marketing)</Text>

              <Flex w='100%' justifyContent='center'>
                <Button width='156px' h='48px' borderRadius='40px' bgColor={theme.colors.blue.default} marginTop='37px' onClick={onClick} color='white'>Iniciar</Button>
              </Flex>

            </Box>

            </Flex>

        </Flex>

  )
}

export { Welcome }
