/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Text, Button, Flex, useTheme, Image } from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { Input } from '../Form/Input'
import { SendIcon } from '../../Assets/IconsComponents/SendIcon'
import ExpandIcon from '../../Assets/expand-icon.png'

import { useScreenSizes } from '../../utils/screenSizes'
import { IModeChatType } from '../Chatbot/Interface/IModeChat'
import { useNavigate } from 'react-router-dom'
import { text } from 'stream/consumers'
import { HandleSendMessageProps } from '../../Hooks/useChat'

interface Props {
  handleSendMessage: (data: HandleSendMessageProps) => Promise<void>
  mode: IModeChatType
}

const ChatbotInput: FC<Props> = ({ handleSendMessage, mode }) => {
  const [text, setText] = useState('')
  const { isMobile } = useScreenSizes()
  const theme = useTheme()
  const navigation = useNavigate()
  const handleInputChange = (e: any) => {
    setText(e.target.value)
  }

  const handleSend = async (e: any): Promise<void> => {
    e.preventDefault()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSendMessage({ text })
    setText('')
  }

  return (
      <Flex width='100%' justifyContent='center' alignItems='center' bgColor={isMobile || mode === 'popup' ? 'transparent' : theme.colors.white.primary} flexDirection="column" position={isMobile ? 'absolute' : 'relative'} height={isMobile ? '110px' : 'auto'} bottom={0} paddingTop='2%' >

        <Flex as="form" w={isMobile ? '90%' : '100%'} maxWidth={ mode === 'popup' ? '388px' : isMobile ? '100%' : '56%'} onSubmit={handleSend} bottom={0} marginBottom={mode === 'popup' ? '13%' : isMobile ? '30px' : '8%'} position='relative' borderRadius="40px" backgroundColor='#EFF4FA' borderWidth={text?.length ? '1px' : 0} borderColor='#667085' marginLeft={isMobile ? '20px' : 0} marginRight={isMobile ? '20px' : 0} >
          <Input name='text' borderRadius='40px' onChange={handleInputChange} value={text} placeholder="Faça uma pergunta" h="48px" w='100%' border={0} padding="10px 20px" color="#4C5773" fontSize="18px" fontWeight={400} backgroundColor='#EFF4FA' _hover={{ backgroundColor: '#EFF4FA', borderRadius: '40px' }} _focus={{ backgroundColor: '#EFF4FA', borderRadius: '40px' }} _placeholder={{ color: '#A0AEC0', fontWeight: 400, fontSize: '18px' }} />
          <Button onClick={handleSend} w="44px" h="44px" alignItems="center" justifyContent="center" p="0" pt="3px" borderRadius='40px' backgroundColor='#EFF4FA' marginRight='10px' _hover={{ backgroundColor: '#EFF4FA' }}>
            <SendIcon />
          </Button>

      </Flex>
      {mode === 'popup' && <Flex position='fixed' bottom='90px' alignItems='center' cursor='pointer' onClick={() => navigation('/ai?fromFloatButton=true')}>
        <Text color={theme.colors.gray.third} marginRight='8px' fontSize='14px' fontWeight={500}>Expandir</Text>
        <Image src={ExpandIcon} w='14px' h='14px' />
      </Flex>}

    </Flex>

  )
}

export { ChatbotInput }
