import React, { FC } from 'react'
import { ChatProvider } from './useChat'

const HooksProvider: FC<any> = ({ children }) => (

    <ChatProvider>{children}</ChatProvider>

)

export { HooksProvider }
