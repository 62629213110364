import { error } from 'console'
import {
  FavoritesApiRemoteService
} from '../../Services/protocols/service-favorites'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class DeleteFavoritesUseCase
implements IUsecase<string, void> {
  constructor (private readonly repository: FavoritesApiRemoteService) {}

  public async handle (id: string): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.deleteFavorite(id)

      if (response.isFailure) {
        return response
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
