/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react'
import { TypeAnimation } from 'react-type-animation'
import { markdownToHtml } from '../../utils/markdownToHtml'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Typed from 'typed.js'
import './custom.css'

const TypeAnimationWithMarkdown = ({ message }: any) => {
  const el = React.useRef(null)

  return (
    <Markdown remarkPlugins={[remarkGfm]}>{message}</Markdown>

  )
}

export default TypeAnimationWithMarkdown
