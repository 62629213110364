/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Flex, Text, useTheme } from '@chakra-ui/react'
import React, { FC, useEffect, useRef } from 'react'
import { TRatingMessageDTO } from '../../../Services/protocols/service-chat'
import { useScreenSizes } from '../../../utils/screenSizes'
import { BotMessage } from '../BotMessage'
import { UserMessage } from '../UserMessage'
import { HandleSendMessageProps, IMessagesChatProps } from '../../../Hooks/useChat'
import { IModeChatType } from '../Interface/IModeChat'
import { ChatbotInput } from '../../ChatbotInput'

interface Props {
  messages: IMessagesChatProps[]
  chatLoading: string
  handleRatingMessage: (data: TRatingMessageDTO) => Promise<void>
  mode: IModeChatType
  handleCreateFavorite: (name: string, messageId: string) => Promise<string | undefined>
  handleSendMessage: (data: HandleSendMessageProps) => Promise<any>
  handleClearChat: () => Promise<void>
  handleDeleteFavorite: (id: string) => Promise<void>

}
const Messages: FC<Props> = ({ messages, chatLoading, handleRatingMessage, mode, handleDeleteFavorite, handleCreateFavorite, handleSendMessage, handleClearChat }) => {
  const { isMobile } = useScreenSizes()
  const theme = useTheme()
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = (): void => {
    if (messagesEndRef.current != null) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, chatLoading])

  return (
    <Flex
      direction="column"
      flex="1"
      width="100%"
      height={isMobile ? '90%' : mode === 'popup' ? '100%' : '100%'}
      overflowY="auto"
      padding="0"
      margin="0"
      marginTop={isMobile ? '160px' : 0}
      paddingBottom={isMobile ? '100px' : 0}
    >
      <Flex width="100%" height={isMobile ? '70vh' : mode === 'popup' ? '100%' : '100%'} >
        <Flex
          flex={1}
          overflow="auto"
          flexDirection="column"
          padding={isMobile || mode === 'popup' ? '0px' : '56px 20% 0 20%'}
          width={isMobile ? '100%' : '915px'}
          >
            {mode === 'popup' && messages?.length > 1 && <Flex bgColor='white' w='93%' position={mode === 'popup' ? 'absolute' : 'relative'} right={mode === 'popup' ? '25px' : 0}>
                <Text ml='auto' fontWeight={400} fontSize='14px' color={theme.colors.blue.default} textDecoration='underline' cursor='pointer' onClick={handleClearChat} >Limpar</Text>
              </Flex>}
            {messages.map((item, index) =>
              item.role === 'assistant'
                ? (
                  <BotMessage key={item.id} message={item.message} id={item.id} showActions={index !== 0} chatId={item.chatId} index={index} chatLoading={chatLoading} handleRatingMessage={handleRatingMessage} mode={mode} lastChild={index === messages?.length - 1} handleSendMessage={handleSendMessage}/>
                  )
                : (
                  <UserMessage key={item.id} chat={item} mode={mode} handleCreateFavorite={handleCreateFavorite} handleDeleteFavorite={handleDeleteFavorite} />
                  )
            )
                }

            <div ref={messagesEndRef} />
          </Flex>
      </Flex>

    </Flex>

  )
}

export { Messages }
