import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'

export interface TCreatThreadIdResponse {
  threadId: string
}

export interface TSendMessageDTO {
  message: string
  threadId: string
  initialData: boolean | null
}
export interface TSendMessageResponse {
  chatId: string
  message: string
}

export interface TRatingMessageDTO {
  chatId: string
  rating: string | undefined
}

export class ChatApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public createThreadId = async (): Promise<Result<TCreatThreadIdResponse>> => {
    try {
      const result = await this.service.post('/thread')

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public sendMessage = async ({ message, threadId, initialData }: TSendMessageDTO): Promise<Result<TSendMessageResponse>> => {
    try {
      const result = await this.service.post('/chat', { message, threadId, initialData })

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public ratingMessage = async ({ chatId, rating }: TRatingMessageDTO): Promise<Result<void>> => {
    try {
      const result = await this.service.patch(`/chat/${chatId}`, { rating })

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
