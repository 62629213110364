import { Box, Flex, Text, useTheme } from '@chakra-ui/react'
import React, { FC } from 'react'
import { IoIosCheckmarkCircle, IoIosInformationCircle } from 'react-icons/io'

interface ICustomProps {
  type: 'success' | 'fail' | 'neutral'
  title?: string
  description?: string
  fromToast?: boolean
}

const ToastCustom: FC<ICustomProps> = ({ type, description, title, fromToast = true }) => {
  const theme = useTheme()

  const getIcon = {
    success: <IoIosCheckmarkCircle color={theme.colors.green[500]} size={25} />,
    fail: <IoIosCheckmarkCircle color={theme.colors.red[500]} size={25}/>,
    neutral: <IoIosInformationCircle color={theme.colors.blue.default} size={25}/>
  }

  const getColor = {
    success: theme.colors.green[300],
    fail: theme.colors.red[50],
    neutral: theme.colors.gray[80]
  }

  return (
        <Flex padding="12px 16px" bgColor={getColor[type] || theme.colors.green[300]} borderRadius={fromToast ? '10px 0px 0px 10px' : '10px'} alignItems='center'>
            {getIcon[type] || <IoIosCheckmarkCircle color={theme.colors.green[200]} />}
            <Box marginLeft='12px'>
                {title && <Text fontWeight={700} fontSize='16px' color={theme.colors.blue[500]}>{title}</Text> }
                {description && <Text fontWeight={400} fontSize='16px' color={theme.colors.blue[500]}>{description}</Text> }
            </Box>
        </Flex>
  )
}

export { ToastCustom }
