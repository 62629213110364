import { Button } from '@chakra-ui/react'
import React, { FC } from 'react'
import { ChevronLeft } from '../../Assets/IconsComponents/ChevronLeft'

const BackButtonHeader: FC = () => {
  return (
        <Button width="42px" height="42px" borderStyle="solid" borderWidth="1px" borderColor="gray.100" borderRadius="100%" alignItems="center" justifyContent="center" backgroundColor="#fff" p="0">
            <ChevronLeft />
        </Button>
  )
}

export { BackButtonHeader }
