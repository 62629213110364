interface IClearCookiesProps {
  name: string
}

interface ISetCookieProps {
  name: string
  value: string
  days: number
}

export const clearCookie = ({ name }: IClearCookiesProps): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=None; Secure`
}

export const setCookie = ({ days, name, value }: ISetCookieProps): void => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  document.cookie = `${name}=${value};${expires};path=/;SameSite=None;Secure`
}

export const getCookie = (cookie: string): string | undefined => {
  const nameEQ = `${cookie}=`
  const cookiesArray = document.cookie.split(';')

  for (let i = 0; i < cookiesArray.length; i++) {
    const cookie = cookiesArray[i].trim()

    if (cookie.startsWith(nameEQ)) {
      return cookie.substring(nameEQ.length)
    }
  }

  return undefined
}
