import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './Styles/theme'

import Router from './Routes'
import { HooksProvider } from './Hooks'

function App () {
  return (
    <HooksProvider>
    <ChakraProvider theme={theme}>

        <Router />
    </ChakraProvider>

    </HooksProvider>
  )
}

export default App
