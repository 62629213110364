/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box, Flex, Text, useDisclosure, useTheme, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react'
import React, { FC, useCallback, useState } from 'react'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { IModeChatType } from '../Interface/IModeChat'
import { TListFavoritesResponse } from '../../../Services/protocols/service-favorites'
import { HandleSendMessageProps } from '../../../Hooks/useChat'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { useScreenSizes } from '../../../utils/screenSizes'

interface Props {
  mode: IModeChatType
  data: TListFavoritesResponse[]
  handleSendMessage: (data: HandleSendMessageProps) => Promise<void>
  handleDeleteFavorite: (id: string) => Promise<void>
}

const Favorites: FC<Props> = ({ mode, data, handleSendMessage, handleDeleteFavorite }) => {
  const theme = useTheme()
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState('')
  const { isMobile } = useScreenSizes()
  const handleOpenModal = useCallback((id: string) => {
    setOpenModal(true)
    setSelectedItem(id)
  }, [])

  return (
    <>
     <Modal isOpen={openModal} onClose={() => setOpenModal(false)} isCentered>
          <ModalOverlay />
          <ModalContent width='295px' height='175px' shadow='box-shadow: 0px 0px 12px 0px #00000026' bgColor={theme.colors.white.primary} border='none' borderRadius='16px'>

          <ModalBody padding="56px 48px 48px 48px">
          <ModalCloseButton w='15px' h='15px' marginTop='5px' marginRight='3px' />
          <Flex direction="column" alignItems="center" >
              <Text fontWeight={700} fontSize='18px' color={theme.colors.blue.default} >Remover favorito?</Text>

              <Flex alignItems="center" marginTop="20px">
                  <Box bgColor={theme.colors.blue.default} padding="4px 16px" borderRadius="40px" cursor="pointer" onClick={() => setOpenModal(false)}>
                      <Text fontWeight={700} fontSize="14px" color={theme.colors.white.primary}>Cancelar</Text>
                  </Box>
                  <Box bgColor={theme.colors.white.primary} padding="4px 16px" borderRadius="40px" cursor="pointer" onClick={async () => {
                    await handleDeleteFavorite(selectedItem)
                    setOpenModal(false)
                  }}>
                      <Text fontWeight={500} fontSize="14px" color={theme.colors.blue.default}>Remover</Text>
                  </Box>
              </Flex>
          </Flex>

          </ModalBody>

          </ModalContent>
      </Modal>
      <Flex
        direction="column"

        width="100%"
        maxWidth="650px"
        // maxWidth="898px"
        paddingX={mode === 'popup' ? '24px' : '24px'}
        height={mode === 'popup' ? '48vh' : isMobile ? '88vh' : '100%'}
        overflowY="auto"
      >
        {data?.map(item => (
          <Flex alignItems='center' key={item.id} marginBottom='24px'>
              <Box
                as='button'
                padding="10px"
                borderRadius={10}
                width="100%"
                textAlign="left"
                marginRight="24px"
                onClick={async () => await handleSendMessage({ text: item.name, refresh: false, favoriteId: item.id })}
                _hover={{ bg: theme.colors.gray[80], fontWeight: 600 }}
              >
                  <Text width='99%' lineHeight='24px' fontWeight={400} color={theme.colors.blue.default} >
                    {capitalizeFirstLetter(item.name)}
                  </Text>
              </Box>
              <FaHeart cursor='pointer' onClick={() => handleOpenModal(item.id)} />
          </Flex>
        ))}
      </Flex>
    </>
  )
}

export { Favorites }
